/* eslint-disable react/no-unused-prop-types */
import { useTranslation } from 'next-i18next';
import Link from 'next-translate-routes/link';
import { useRouter } from 'next-translate-routes/router';
import type { Dispatch, FC, SetStateAction } from 'react';

import { Button, Icon } from '@/components';
import { useExternalRedirect } from '@/hooks/useExternalRedirect';
import useUserInformation from '@/hooks/useUserInformation';
import { useAuthModalsControl, useAuthStore } from '@/store';
import type { IBankDepositCard } from '@/types';
import { AnalyticsCategory, AnalyticsEvent } from '@/types/analytics';
import { useShowModal } from '@/usecases/modals';
import { analyticServices, cn } from '@/utils';

interface IButtonsDepositProps {
  bank: IBankDepositCard;
  setConfirmPopup: Dispatch<SetStateAction<boolean>>;
  partnerType?: string | null;
}

export const ButtonsDeposit: FC<IButtonsDepositProps> = ({
  setConfirmPopup,
  bank,
}) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { token } = useAuthStore((state) => state);
  const { showQuestionOpenDepositModal } = useShowModal();
  const { redirectToExternalLink } = useExternalRedirect();
  const { features } = useUserInformation();

  const { setRedirectUri, openAuthModal } = useAuthModalsControl(
    (state) => state,
  );

  const hanldehowToChoose = () => {
    analyticServices.sendEvent({
      action: AnalyticsEvent.clickHowToChoose,
      category: AnalyticsCategory.deposit,
      label: 'How to choose',
    });
  };

  const handleVisitBankSiteClick = () => {
    setConfirmPopup(true);
  };

  const sendAnalytics = async (typeEvent: AnalyticsEvent) => {
    analyticServices.sendEvent({
      action: typeEvent,
      category: AnalyticsCategory.deposit,
      label: 'Deposit ID',
      payload: {
        bank_index: bank.bankIndex,
        product_index: bank.productIndex,
      },
    });
  };

  const handlePressOpenOnline = (e: any) => {
    e.preventDefault();
    sendAnalytics(AnalyticsEvent.openOnline);
    sendAnalytics(AnalyticsEvent.clickGetStartedOrOpenOnline);
    if ((token && !features.profile) || bank.partnerType !== 'platform') {
      redirectToExternalLink({
        onSuccess: () => {
          showQuestionOpenDepositModal({
            productIndex: bank.productIndex,
          });
        },
        bank,
      });
      return;
    }
    const uri = `/deposits/${bank.productIndex}/apply/`;
    if (!token) {
      openAuthModal('platform');
      setRedirectUri(uri);
      return;
    }
    router.push({
      pathname: uri,
    });
  };

  return (
    <div className="flex flex-col justify-center gap-4 md:flex-row md:items-start md:justify-start">
      {bank.isPartnerBank ? (
        <Button
          className={cn(
            'body2-bold px-6 py-[18px] min-w-full md:min-w-[199px] rounded-full',
            'bg-blue-500 text-white hover:bg-blue-550',
          )}
          props={{ onClick: handlePressOpenOnline }}
        >
          {t('deposit_card.button_open_deposit_online')}
        </Button>
      ) : (
        <button
          type="button"
          className={cn(
            'body2-bold px-6 py-[18px] min-w-full md:min-w-[183px] rounded-full flex justify-center items-center gap-1',
            'bg-blue-100 text-blue-500 hover:bg-blue-150 text-center',
          )}
          onClick={handleVisitBankSiteClick}
        >
          {t('deposit_card.button_visit_banks_site')}
          <Icon
            name="rightArrow"
            className="size-3 -rotate-45 text-transparent"
          />
        </button>
      )}
      <Link
        href={t('faq_page.question_1.link')}
        type="button"
        className={cn(
          'body2-bold px-6 py-[18px] min-w-full md:min-w-[160px] rounded-full',
          'bg-blue-100 text-blue-500 hover:bg-blue-150 text-center',
        )}
        onClick={hanldehowToChoose}
      >
        {t('deposit_card.button_how_to_choose')}
      </Link>
    </div>
  );
};
